<template>
  <div class="create-artist-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Artist' }"
        >藝術家管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>創建藝術家</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <el-form
        label-position="top"
        ref="artistForm"
        :model="artist_form"
        :rules="artist_rules"
      >
        <el-form-item label="藝術家照片" prop="avatar_file">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="handleUpload"
            :before-upload="handleBeforeUpload"
            accept=".jpeg,.jpg,.png"
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="avatar"
              alt="photo"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="啟用" prop="active">
          <el-radio-group v-model="artist_form.active" size="small">
            <el-radio :label="true" :border="true">啟用</el-radio>
            <el-radio :label="false" :border="true">停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input v-model="artist_form.name_zh"></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input v-model="artist_form.name_en"></el-input>
        </el-form-item>
        <el-form-item label="中文國籍名稱" prop="country_zh">
          <el-input v-model="artist_form.country_zh"></el-input>
        </el-form-item>
        <el-form-item label="英文國籍名稱" prop="country_en">
          <el-input v-model="artist_form.country_en"></el-input>
        </el-form-item>
        <el-form-item label="中文簡歷" prop="introduction_zh">
          <el-input
            type="textarea"
            v-model="artist_form.introduction_zh"
            :rows="5"
          ></el-input>
        </el-form-item>
        <el-form-item label="英文簡歷" prop="introduction_en">
          <el-input
            type="textarea"
            v-model="artist_form.introduction_en"
            :rows="5"
          ></el-input>
        </el-form-item>

        <el-form-item label="中文履歷" prop="resume_zh">
          <div ref="js-editor-resume_zh"></div>
        </el-form-item>

        <el-form-item label="英文履歷" prop="resume_en">
          <div ref="js-editor-resume_en"></div>
        </el-form-item>

        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { createArtist } from "@/api/artist";
import E from "wangeditor";

export default {
  name: "CreateArtist",
  data() {
    return {
      artist_form: {
        avatar_file: "",
        active: true,
        name_zh: "",
        name_en: "",
        country_zh: "",
        country_en: "",
        introduction_zh: "",
        introduction_en: "",
        resume_zh: "",
        resume_en: "",
      },
      artist_rules: {
        avatar_file: [
          { required: true, message: "請上傳圖片", trigger: "change" },
        ],
        name_zh: [
          { required: true, message: "請輸入名稱", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        name_en: [
          { required: true, message: "請輸入名稱", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        country_zh: [
          { required: true, message: "請輸入國籍", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        country_en: [
          { required: true, message: "請輸入國籍", trigger: "blur" },
          { max: 128, message: "長度過長", trigger: "blur" },
        ],
        introduction_en: [
          { required: true, message: "請輸入介紹", trigger: "blur" },
        ],
        introduction_zh: [
          { required: true, message: "請輸入介紹", trigger: "blur" },
        ],
      },
      preview_url: "",
    };
  },
  mounted() {
    this.handleInitEditor();
  },
  methods: {
    handleInitEditor() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith(["js-editor"])) {
          const editor = new E(this.$refs[ref]);
          const key = ref.replace("js-editor-", "");

          editor.config.onchange = (html) => {
            this.artist_form[key] = html;
          };

          // using base64 to store image
          editor.config.uploadImgShowBase64 = true;
          // disable online image url
          editor.config.showLinkImg = false;
          // change language to en
          editor.config.lang = "en";

          // import i18n
          editor.i18next = window.i18next;

          editor.config.placeholder = "請輸入內容";
          editor.config.menus = [
            "fontSize",
            "bold",
            "head",
            "link",
            "italic",
            "strikeThrough",
            "underline",
            "image",
          ];
          editor.create();
        }
      });
    },
    handleBeforeUpload(file) {
      const imageType = ["image/jpeg", "image/jpg", "image/png"];

      if (imageType.indexOf(file.type) === -1) {
        this.$message.error("請上傳jpg, jpeg, png的格式");
        return false;
      }

      return true;
    },
    handleUpload(file) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.preview_url = reader.result;
      });

      if (file) {
        reader.readAsDataURL(file.file);
        this.artist_form.avatar_file = file.file;
      }
    },
    handleSubmit() {
      this.$refs.artistForm.validate(async (valid) => {
        if (valid) {
          const formData = new FormData();

          Object.keys(this.artist_form).forEach((key) => {
            formData.append(key, this.artist_form[key]);
          });

          await createArtist(formData);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          await this.handleRedirect();
        }
      });
    },
    async handleRedirect() {
      await this.$router.push({ name: "Artist" });
    },
  },
};
</script>

<style lang="scss" scoped>
.create-artist-block {
  ::v-deep.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }
  ::v-deep.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  ::v-deep.avatar {
    width: auto;
    max-width: 800px;
    display: block;
  }
}
</style>
